import { Box, Button, FormControl, FormErrorMessage, FormLabel, Heading, Input, Stack, Textarea, useToast } from "@chakra-ui/react";
import { useState } from "react";

function Settings() {
  const toast = useToast();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState("");
  const [website, setWebsite] = useState("");
  const [location, setLocation] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      // TODO: submit form data to server
      toast({
        title: "Settings saved.",
        description: "Your settings have been saved successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      setErrors(formErrors);
    }
  }

  function validateForm() {
    const errors: { [key: string]: string } = {};
    if (!name) {
      errors.name = "Please enter your name";
    }
    if (!email) {
      errors.email = "Please enter your email";
    } else if (!isValidEmail(email)) {
      errors.email = "Please enter a valid email address";
    }
    return errors;
  }

  function isValidEmail(email: string) {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  return (
    <Box maxW="960px" mx="auto" py={8} px={4}>
      <Heading as="h1" size="2xl" mb={8}>Settings</Heading>
      <Box p={8} borderRadius="md">
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <FormControl id="name" isRequired>
              <FormLabel>Name</FormLabel>
              <Input type="text" value={name} onChange={(event) => setName(event.target.value)} />
              {errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <Input type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
              {errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
            </FormControl>
            <FormControl id="website">
              <FormLabel>Website</FormLabel>
              <Input type="url" value={website} onChange={(event) => setWebsite(event.target.value)} />
            </FormControl>
            <FormControl id="location">
              <FormLabel>Location</FormLabel>
              <Input type="text" value={location} onChange={(event) => setLocation(event.target.value)} />
            </FormControl>
            <FormControl id="bio">
              <FormLabel>Bio</FormLabel>
              <Textarea value={bio} onChange={(event) => setBio(event.target.value)} />
            </FormControl>
            <Button type="submit">Save Settings</Button>
          </Stack>
        </form>
      </Box>
    </Box>
  );
}

export default Settings;
