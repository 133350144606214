import { useState, useEffect } from "react";
import { Auth } from "@aws-amplify/auth";
import { User } from "../../store/AuthProvider";
import { emptyUser } from "./constants";


function useCurrentUser() {
  const [user, setUser] = useState<User>(emptyUser);

  useEffect(() => {
    async function fetchUser() {
      try {
        const userData = await Auth.currentAuthenticatedUser();
        setUser(userData);
      } catch (error) {
        console.error("Error fetching user:", error);
        setUser(emptyUser);
      }
    }

    fetchUser();
  }, []);

  return user;
}

export default useCurrentUser;
