import { SearchIcon } from "@chakra-ui/icons";
import { Button, Icon, Input, Stack, useBreakpointValue } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface SearchBarProps {
  setSearchQuery: (query: string) => void;
  renderSearchPage?: boolean
}

export function SearchBar({ setSearchQuery, renderSearchPage = true }: SearchBarProps) {
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const columns = useBreakpointValue({ base: 2, lg: 4, });

  const handleSearch = (event: React.FormEvent) => {
    event.preventDefault();
    setSearchQuery(searchInput);
    if (renderSearchPage) {
      navigate(`/search?query=${searchInput}`);
    }
  };

  return (
    <form onSubmit={handleSearch}>
      <Stack spacing={4} direction="row" mb={8}>
        <Input
          placeholder="Search recipes"
          value={searchInput}
          onChange={(event) => setSearchInput(event.target.value)}
        />
        <Button type="submit" variant="outline">
          <Icon as={SearchIcon} />
        </Button>
      </Stack>
    </form>
  );
}