import { createContext, useContext, useReducer, Dispatch } from "react";
import { Recipe, getRecipes } from "../api/recipes/recipes";
import { RecipeCardProps } from "../components/recipeCard/RecipeCard";

type RecipeAction = {
  type: "SET_RECIPES";
  payload: Recipe[];
};

type RecipeContextType = {
  recipes: Recipe[];
  setRecipes: (recipes: Recipe[]) => void;
};

const RecipeContext = createContext<RecipeContextType | undefined>(undefined);

export const useRecipes = () => {
  const context = useContext(RecipeContext);
  if (!context) {
    throw new Error("useRecipes must be used within a RecipeProvider");
  }
  return context;
};

const recipeReducer = (state: Recipe[], action: RecipeAction): Recipe[] => {
  switch (action.type) {
    case "SET_RECIPES":
      return action.payload;
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

interface RecipeProviderProps {
  children: React.ReactNode;
}

export const RecipeProvider: React.FC<RecipeProviderProps> = ({ children }) => {
  const [recipes, dispatch] = useReducer(recipeReducer, []);

  const setRecipes = (recipes: Recipe[]) => {
    dispatch({ type: "SET_RECIPES", payload: recipes });
  };

  return (
    <RecipeContext.Provider value={{ recipes, setRecipes }}>
      {children}
    </RecipeContext.Provider>
  );
};
