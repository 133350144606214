import config from "../../config/config";
import { Recipe } from "../recipes/recipes";

export async function getSuggestions(searchQuery: string): Promise<Recipe[]> {
  const response = await fetch(`${config.API_URL}/ai?searchQuery=${searchQuery}`);

  if (!response.ok) {
    throw new Error(`Error fetching suggestions for ${searchQuery}: ${response.statusText}`);
  }

  const recipeData: Recipe[] = await response.json() as Recipe[];
  return recipeData;
}