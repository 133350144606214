import { Badge, Box, Flex, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export type RecipeCardProps = {
  id: string;
  title: string;
  author: string;
  imageUrl: string;
  description: string;
  rating: number;
  category: string;
};

function RecipeCard({
  id,
  title,
  author,
  imageUrl,
  description,
  rating,
  category,
}: RecipeCardProps) {
  return (
    <Link to={`/recipes/${id}`}>
      <Box
        maxW="sm"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        height="100%"
        display="flex"
        flexDirection="column"
      >
        {imageUrl !== "" && <Image src={imageUrl} alt={title} loading="lazy" />}
        <Box p="6" flex="1" display="flex" flexDirection="column">
          <Box alignItems="baseline">
            <Text fontWeight="semibold" fontSize="lg" mr={2}>
              {title}
            </Text>
          </Box>
          <Box
            mt="1"
            fontWeight="normal"
            as="h4"
            lineHeight="tight"
            isTruncated
            flex="1"
          >
            {description}
          </Box>
          <Box mt={2}>
            <Badge>{category}</Badge>
          </Box>
          <Flex mt="2" alignItems="center">
            <Box as="span" color="gray.600" fontSize="sm">
              {`Recipe by ${author}`}
            </Box>
          </Flex>
        </Box>
      </Box>
    </Link>
  );
}

export default RecipeCard;
