import React, { memo, useEffect, useState } from 'react';
import {
  Box,
  Input,
  Button,
  Text,
  VStack,
  Spinner,
  Container,
  Card,
  CardBody,
  useToast,
  Textarea
} from '@chakra-ui/react';
import OpenAI from 'openai';
import { MessageContentImageFile, MessageContentText } from 'openai/resources/beta/threads/messages/messages';
import { Run } from 'openai/resources/beta/threads/runs/runs';
import config from '../config/config';
import { CalendarEvent, createOrUpdateEvent } from '../api/calendar';
import { Recipe, addRecipe } from '../api/recipes/recipes';

const ASSISTANT_ID = config.RECIPE_API_ASSISTANT;

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

interface Message {
  role: 'user' | 'assistant';
  content: MessageContentText | MessageContentImageFile;
}

const ChatMessage = memo(({ role, content }: Message) => {
  const isUser = role === 'user';

  return (
    <Box
      bg={isUser ? '#f0f0f0' : '#d8d8d8'}
      p='10px'
      borderRadius='10px'
      maxWidth='80%'
      ml={isUser ? 'auto' : '0'}
      mr={!isUser ? 'auto' : '0'}
      mb='10px'
    >
      <Text textAlign={isUser ? 'right' : 'left'}>
        {/* Replace with the equivalent markdown component or logic */}
        {content.type === 'text'
          ? content.text.value
          : content.image_file.file_id}
      </Text>
    </Box>
  );
});

export const ChatPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [thread, setThread] = useState<any>();

  const messageStyles = {
    user: {
      backgroundColor: '#f0f0f0', // Light grey for user messages
      padding: '10px',
      borderRadius: '10px',
      maxWidth: '80%',
      marginLeft: 'auto',
      marginBottom: '10px',
    },
    assistant: {
      backgroundColor: '#d8d8d8', // Darker grey for assistant messages
      padding: '10px',
      borderRadius: '10px',
      maxWidth: '80%',
      marginRight: 'auto',
      marginBottom: '10px',
    }
  };

  useEffect(() => {
    const fetchAssistantAndThread = async () => {
      console.log('Fetching assistant and creating thread...');
      try {
        await openai.beta.assistants.retrieve(ASSISTANT_ID);
        setThread(await openai.beta.threads.create({}));
      } catch (error) {
        console.error('Error fetching assistant or creating thread:', error);
      }
    };
    fetchAssistantAndThread();
  }, []);


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const sendMessage = async () => {
    if (!inputValue.trim()) return;

    setInputValue('');
    setIsLoading(true);

    let inputMessage: Message = {
      role: 'user',
      content: {
        type: 'text',
        text: {
          value: inputValue
        }
      } as MessageContentText
    };

    setMessages(messages => [...messages, inputMessage]);

    await openai.beta.threads.messages.create(
      thread.id,
      {
        role: "user",
        content: inputValue
      }
    );

    var run: Run = await openai.beta.threads.runs.create(
      thread.id,
      {
        assistant_id: ASSISTANT_ID
      }
    );

    await waitForRunCompletion(thread.id, run.id);

    const assistantMessageList = await openai.beta.threads.messages.list(thread.id);
    const assistantMessage = assistantMessageList.data[0].content[0];

    setMessages(messages => [...messages, {
      role: 'assistant',
      content: assistantMessage as MessageContentText | MessageContentImageFile
    }]);

    setIsLoading(false)
  };

  return (
    <Container maxW="sm" pt={4}>
      <Card>
        <CardBody>
          <Box maxW='600px' m='auto' mb='50px'>
            <Text fontSize='lg' mb={4}>
              Chat Assistant
            </Text>
            <VStack spacing={4}>
              {messages.map((message, index) => (
                <ChatMessage key={index} role={message.role} content={message.content} />
              ))}
              {isLoading && (
                <Box display='flex' justifyContent='center'>
                  <Spinner />
                </Box>
              )}
            </VStack>
            <Box
              as="form"
              display='flex'
              alignItems='center'
              mt='20px'
              onSubmit={(e) => {
                e.preventDefault();
                sendMessage();
              }}
            >
              <Textarea // Changed from Input to Textarea
                flex={1}
                variant="outline"
                placeholder="What recipe are you making?"
                value={inputValue}
                //@ts-ignore
                onChange={handleInputChange}
                size='sm'
                resize='both' // Optional, if you want to disable manual resizing
                autoResize // Enable automatic resizing
              />
              <Button colorScheme='blue' ml={2} onClick={sendMessage}>
                Enter
              </Button>
            </Box>
          </Box>
        </CardBody>
      </Card>
    </Container>
  );
};

const waitForRunCompletion = async (threadId: string, runId: string): Promise<Run> => {
  let runStatus = await openai.beta.threads.runs.retrieve(threadId, runId);

  if (runStatus.status === 'requires_action' && runStatus.required_action?.submit_tool_outputs.tool_calls[0]) {
    const apiInputJSON: Recipe = JSON.parse(runStatus.required_action.submit_tool_outputs.tool_calls[0].function.arguments);
    const apiResponse = await addRecipe(apiInputJSON);

    runStatus = await openai.beta.threads.runs.submitToolOutputs(threadId, runId, {
      tool_outputs: [
        {
          tool_call_id: runStatus.required_action.submit_tool_outputs.tool_calls[0].id,
          output: JSON.stringify(apiResponse)
        }
      ]
    });
  }

  if (runStatus.status !== 'completed') {
    await new Promise(resolve => setTimeout(resolve, 5000));
    return waitForRunCompletion(threadId, runId);
  }

  return runStatus;
};



export default ChatPage;