const config = {
  "dev": {
    "API_URL": "https://thecookbook.api.jonhenshaw.dev/dev",
    "REGION": "us-east-1",
    "CALENDAR_API_URL": "https://5y0odak699.execute-api.us-east-1.amazonaws.com/prod",
    "CALENDAR_ASSISTANT_ID": "asst_pJgl53zzKTchPGWtRBMhte5C",
    RECIPE_API_ASSISTANT: "asst_FA1y1c3X73Zn6d9DOapYJK3T"
  },
  "prod": {
    "API_URL": "https://thecookbook.api.jonhenshaw.dev/prod",
    "REGION": "us-east-1",
    "CALENDAR_API_URL": "https://5y0odak699.execute-api.us-east-1.amazonaws.com/prod",
    "CALENDAR_ASSISTANT_ID": "asst_pJgl53zzKTchPGWtRBMhte5C",
    RECIPE_API_ASSISTANT: "asst_FA1y1c3X73Zn6d9DOapYJK3T"
  }
}

export default process.env.NODE_ENV === 'development' ? config.dev : config.prod;