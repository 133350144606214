import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorMode,
} from "@chakra-ui/react";
import {
  ChatIcon,
  HamburgerIcon,
  MoonIcon,
  Search2Icon,
  SettingsIcon,
  SunIcon,
} from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "../../utils/auth";
import { useAuth } from "../../store/AuthProvider";
import useCurrentUser from "../../api/auth/useCurrentUser";
import { HiOutlineUserCircle } from "react-icons/hi";
import { RiLoginCircleLine } from "react-icons/ri";
import { useRecipes } from "../../store/RecipeContext";
import { useEffect } from "react";

export interface MenuItem {
  label: string;
  href: string;
}

const menuItems: MenuItem[] = [
  { label: "Breakfast", href: "breakfast" },
  { label: "Lunch", href: "lunch" },
  { label: "Dinner", href: "dinner" },
  { label: "Dessert", href: "dessert" },
  { label: "Vegetarian", href: "vegetarian" },
  { label: "Vegan", href: "vegan" },
  { label: "Gluten-free", href: "gluten-free" },
  { label: "Keto", href: "keto" },
  { label: "Low-carb", href: "low-carb" },
];

function Header() {
  const { colorMode, toggleColorMode } = useColorMode();
  const user = useCurrentUser();
  const navigate = useNavigate();

  const { recipes, setRecipes } = useRecipes();

  const handleSearchClick = () => {
    navigate("/search");
  };

  const handleChatClick = () => {
    navigate("/chat");
  };

  return (
    <Box bg=".100" px={4} py={2}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        maxW="960px"
        mx="auto"
      >
        <Box>
          <Menu>
            <MenuButton as={Button} variant="ghost">
              <IconButton
                aria-label="Menu"
                icon={<HamburgerIcon />}
                variant="ghost"
              />
            </MenuButton>
            <MenuList>
              {menuItems.map((item) => (
                <Link to={`/explore/${item.href}`}>
                  <MenuItem key={item.label}>{item.label}</MenuItem>
                </Link>
              ))}
            </MenuList>
          </Menu>
        </Box>
        <Link to="/">
          <Heading size="md" fontFamily="Roboto Slab">
            thecookbook.io
          </Heading>
        </Link>
        <Box>
          <Menu>
            <MenuButton
              as={IconButton}
              variant="ghost"
              aria-label="Account"
              icon={
                user.username ? (
                  <Icon as={HiOutlineUserCircle} boxSize={5} />
                ) : (
                  <Icon as={RiLoginCircleLine} boxSize={5}>
                    Account
                  </Icon>
                )
              }
            >
              Account
            </MenuButton>
            <MenuList>
              <Link to="/profile">
                <MenuItem>{user.username ? "Profile" : "Login"}</MenuItem>
              </Link>
              {!user.username && (
                <Link to="/profile">
                  <MenuItem>Sign-Up</MenuItem>
                </Link>
              )}

              {/* <Link to="/settings">
                <MenuItem>Settings</MenuItem>
              </Link> */}

              <Link to="/chat">
                <MenuItem>Chat</MenuItem>
              </Link>

              {user.username !== "" && (
                <MenuItem onClick={signOut}>Logout</MenuItem>
              )}
            </MenuList>
          </Menu>
          <IconButton
            ml={2}
            variant="ghost"
            aria-label="Chat"
            icon={<ChatIcon />}
            onClick={handleChatClick}
          />
          <IconButton
            ml={2}
            variant="ghost"
            aria-label="Search"
            icon={<Search2Icon />}
            onClick={handleSearchClick}
          />
          <IconButton
            ml={2}
            variant={"ghost"}
            aria-label="Toggle color mode"
            icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            onClick={toggleColorMode}
          />
        </Box>
      </Flex>
    </Box>
  );
}

export default Header;
