import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button, MenuItem } from '@chakra-ui/react';

interface SignOutButtonProps {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

export async function signOut() {
  try {
    await Auth.signOut();
    window.location.reload();
    console.log('User signed out');
  } catch (error) {
    console.log('Error signing out:', error);
  }
}

function SignOutButton({ onSuccess, onError }: SignOutButtonProps) {
  const [isLoading, setIsLoading] = useState(false);

  async function handleSignOut() {
    setIsLoading(true);
    try {
      await Auth.signOut();
      console.log('User signed out');
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      console.log('Error signing out:', error);
      if (onError) {
        onError(error as Error);
      }
    } finally {
      setIsLoading(false);
    }
  }
}

export default SignOutButton;
