import config from '../../config/config'

export interface Comment {
  commentId: string;
  recipeId: string;
  text: string;
  author: string;
  timestamp: string;
}

export async function getComment(id: string): Promise<Comment> {
  const response = await fetch(`${config.API_URL}/comments/${id}`);

  if (!response.ok) {
    throw new Error(`Error fetching comment with ID ${id}: ${response.statusText}`);
  }

  const commentData: Comment = await response.json() as Comment;
  return commentData;
}

export async function addComment(comment: Comment): Promise<void> {
  const response = await fetch(`${config.API_URL}/comments/${comment.recipeId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(comment),
  });

  if (!response.ok) {
    throw new Error(`Error adding comment: ${response.statusText}`);
  }
}

export async function getComments(recipeId: string): Promise<Comment[]> {
  console.log("recipeId", recipeId)
  const response = await fetch(`${config.API_URL}/comments/${recipeId}`);

  console.log("response", response)

  if (!response.ok) {
    console.log("response", response)
    throw new Error(`Error fetching comments: ${response.statusText}`);
  }

  const commentsData: Comment[] = await response.json() as Comment[];
  return commentsData;
}
