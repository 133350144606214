import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  Text,
  Wrap,
  WrapItem,
  useBreakpointValue,
} from "@chakra-ui/react";
import useCurrentUser from "../api/auth/useCurrentUser";
import { User } from "../store/AuthProvider";
import { Authenticator } from "@aws-amplify/ui-react";
import { useEffect } from "react";
import { useRecipes } from "../store/RecipeContext";
import { getRecipes } from "../api/recipes/recipes";
import RecipeCard from "../components/recipeCard/RecipeCard";

function Profile() {
  const user: User = useCurrentUser();
  const { recipes, setRecipes } = useRecipes();
  const columns = useBreakpointValue({ base: 2, lg: 4, });

  useEffect(() => {
    if (recipes.length === 0) {
      const fetchRecipes = async () => {
        const allRecipes = await getRecipes();
        setRecipes(allRecipes);
      };
      fetchRecipes();
    }
  }, [user.signInUserSession, recipes]);

  const userRecipes = recipes.filter(
    (recipe) => recipe.author === user.username
  );

  return (
    <Authenticator loginMechanisms={["username", "email"]}>
      <Box maxW="960px" mx="auto" py={8} px={4}>
        <Heading as="h1" size="2xl" mb={8}>
          Profile
        </Heading>
        <Box p={8} borderRadius="lg">
          {user ? (
            <>
              <Heading as="h2" size="xl" mb={4}>
                {user ? user.username : ""}
              </Heading>
              <Text fontSize="xl" mb={4}>
                {user.signInUserSession.idToken.payload.email}
              </Text>
              <Heading as="h3" size="lg" mb={4}>
                Your Recipes
              </Heading>
              <SimpleGrid columns={columns} gap={4}>
                {userRecipes.map((recipe) => (
                  <RecipeCard
                    id={recipe.id || ""}
                    author={recipe.author || ""}
                    title={recipe.title}
                    imageUrl={recipe.imageUrl || ""}
                    description={recipe.description || ""}
                    rating={recipe.rating || 4}
                    category={recipe.category}
                  />
                ))}
              </SimpleGrid>
            </>
          ) : (
            <Text fontSize="xl" mb={4}>
              Loading...
            </Text>
          )}
          {/* <Button colorScheme="blue">Edit Profile</Button> */}
        </Box>
      </Box>
    </Authenticator>
  );
}

export default Profile;
