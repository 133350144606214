import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { Recipe, addRecipe } from "../api/recipes/recipes";
import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router-dom";
import useCurrentUser from "../api/auth/useCurrentUser";
import { Authenticator } from "@aws-amplify/ui-react";

function AddRecipe() {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [ingredients, setIngredients] = useState("");
  const [instructions, setInstructions] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  const navigate = useNavigate();
  const user = useCurrentUser();
  const toast = useToast()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (
      title.trim() === "" ||
      category.trim() === "" ||
      ingredients.trim() === "" ||
      instructions.trim() === ""
    ) {
      alert("Please fill in all required fields.");
      setIsLoading(false);
      return;
    }

    // TODO: Add Notes section to recipe
    const newRecipe: Recipe = {
      id: uuid(),
      author: user.username || "Anonymous", // Replace with actual author
      title,
      category,
      description,
      imageUrl,
      ingredients: ingredients.split("\n").filter(row => row.length !== 0),
      instructions: instructions.split("\n").filter(row => row.length !== 0),
    };
    await addRecipe(newRecipe);
    toast({
      position: 'top',
      title: 'Recipe added!',
      description: "Your recipe has been successfully added.",
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
    navigate(`/recipes/${newRecipe.id}`);
    // Redirect to the homepage or display a success message
  };

  return (
    <Authenticator loginMechanisms={["username", "email"]}>
      <Box maxW="960px" mx="auto" py={8} px={4}>
        <Heading as="h1" size="2xl" mb={8}>
          Add a New Recipe
        </Heading>
        <Box shadow="lg" borderRadius="md" px={8} py={6}>
          <form onSubmit={handleSubmit}>
            <FormControl id="title" mb={4} isRequired>
              <FormLabel>Title</FormLabel>
              <Input
                type="text"
                placeholder="Enter the recipe title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormControl>
            <FormControl id="category" mb={4} isRequired>
              <FormLabel>Category</FormLabel>
              <Input
                type="text"
                placeholder="Enter the recipe category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </FormControl>
            <FormControl id="description" mb={4}>
              <FormLabel>Description</FormLabel>
              <Textarea
                placeholder="Enter the recipe description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>
            <FormControl id="ingredients" mb={4} isRequired>
              <FormLabel>Ingredients (one per line)</FormLabel>
              <Textarea
                placeholder="Enter the recipe ingredients"
                value={ingredients}
                onChange={(e) => setIngredients(e.target.value)}
              />
            </FormControl>
            <FormControl id="instructions" mb={4} isRequired>
              <FormLabel>Instructions (one per line)</FormLabel>
              <Textarea
                placeholder="Enter the recipe instructions"
                value={instructions}
                onChange={(e) => setInstructions(e.target.value)}
              />
            </FormControl>
            <FormControl id="imageUrl" mb={4} isRequired>
              <FormLabel>Image URL</FormLabel>
              <Input
                type="text"
                placeholder="Enter image address"
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
              />
            </FormControl>
            <Button type="submit" colorScheme="teal">
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </Authenticator>
  );
}

export default AddRecipe;
