import {
  Box,
  Center,
  Flex,
  Heading,
  Icon,
  useBreakpointValue,
  SimpleGrid,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Recipe } from "../api/recipes/recipes";
import RecipeCard from "../components/recipeCard/RecipeCard";
import NotFound from "./NotFound";
import { getSuggestions } from "../api/ai/ai";
import { SpinnerIcon } from "@chakra-ui/icons";
import { SearchBar } from "../components/searchBar/searchBar";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SearchResults() {
  const query = useQuery();
  const searchQuery = query.get("query") || "";
  const [filteredRecipes, setFilteredRecipes] = useState<Recipe[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const columns = useBreakpointValue({ base: 2, lg: 3, });

  const fetchSuggestions = async () => {
    setIsLoading(true);
    const suggestions = await getSuggestions(searchQuery);
    setFilteredRecipes(suggestions);
    setIsLoading(false);
  };

  useEffect(() => {
    if (searchQuery) {
      fetchSuggestions();
    } else {
      setIsLoading(false);
    }
  }, [searchQuery]);

  const handleSearch = async (query: string) => {
    const suggestions = await getSuggestions(query);
    setFilteredRecipes(suggestions);
  };

  return (
    <Box maxW="960px" mx="auto" py={8} px={4}>
      {searchQuery ? (
        <Heading as="h1" size="2xl" mb={8}>
          Top Results for "{searchQuery}"
        </Heading>
      ) : (
        <Heading as="h1" size="2xl" mb={8}>
          Search Recipes
        </Heading>
      )}

      <SearchBar setSearchQuery={handleSearch} />
      {isLoading && (
        <Flex justifyContent="center" alignItems="center" height="100vh">
          <Icon as={SpinnerIcon} />
        </Flex>
      )}

      {filteredRecipes.length > 0 ?
        (<SimpleGrid columns={columns} gap={4}>
          {filteredRecipes.map((recipe) => (
            <RecipeCard
              id={recipe.id || ""}
              author={recipe.author || ""}
              title={recipe.title}
              imageUrl={recipe.imageUrl || ""}
              description={recipe.description || ""}
              rating={recipe.rating || 4}
              category={recipe.category}
            />
          ))}
        </SimpleGrid>
        ) : (
          !isLoading &&
          searchQuery && (
            <Center flexDirection="column">
              <NotFound message="no results :(" />
            </Center>
          )
        )}
    </Box>
  );
}

export default SearchResults;
