import {
  Box,
  Center,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  VStack,
  Icon,
  Stack,
} from "@chakra-ui/react";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

function Home() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = () => {
    if (searchQuery) {
      navigate(`/search?query=${searchQuery}`);
    }
  };


  return (
    <Flex height="80vh" alignItems="center">
      <Box px={4} maxW="960px" mx="auto">
        <Center mb={8}>
          <Heading as="h1" size="2xl" fontFamily="Roboto Slab">
            Find Recipes
          </Heading>
        </Center>
        <Box maxW="400px" mx="auto">
          <Stack direction="row">
            <InputGroup>
              {/* <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray.300" />}
              /> */}
              <Input
                type="text"
                placeholder="Search for recipes"
                size="md"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
            </InputGroup>
            <Button type="submit" variant="outline" onClick={handleSearch}>
              <Icon as={SearchIcon} />
            </Button>
          </Stack>
        </Box>
        <Center mt={8}>
          <VStack spacing={4}>
            <Link to="/explore">
              <Button colorScheme="teal" size="md" variant="solid">
                Explore Recipes
              </Button>
            </Link>
            <Link to="/recipes/add">
              <Button
                leftIcon={<AddIcon />}
                colorScheme="teal"
                size="md"
                variant="ghost"
              >
                Add a Recipe
              </Button>
            </Link>
          </VStack>
        </Center>
      </Box>
    </Flex>
  );
}

export default Home;
