import { User } from "../../store/AuthProvider";

export const emptyUser: User = {
  username: "",
  pool: {
    userPoolId: "",
    clientId: "",
    client: {
      endpoint: "",
      fetchOptions: {},
    },
    advancedSecurityDataCollectionFlag: false,
    storage: {},
  },
  Session: null,
  client: {
    endpoint: "",
    fetchOptions: {},
  },
  signInUserSession: {
    idToken: {
      payload: {
        email: "",
      },
    },
    accessToken: {
      jwtToken: "",
    },
    refreshToken: {
      jwtToken: "",
    },
    clockDrift: 0,
  },
};
