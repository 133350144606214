import config from '../../config/config'

export interface Recipe {
  id?: string;
  author: string;
  title: string;
  category: string;
  description?: string;
  ingredients: string[];
  instructions: string[];
  imageUrl?: string;
  rating?: number;
  embedding?: string;
}

export async function getRecipe(id: string): Promise<Recipe> {
  const response = await fetch(`${config.API_URL}/recipes/${id}`);

  if (!response.ok) {
    throw new Error(`Error fetching recipe with ID ${id}: ${response.statusText}`);
  }

  const recipeData: Recipe = await response.json() as Recipe;
  return recipeData;
}

export async function addRecipe(recipe: Recipe): Promise<void> {
  const response = await fetch(`${config.API_URL}/recipes`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(recipe),
  });

  if (!response.ok) {
    throw new Error(`Error adding recipe: ${response.statusText}`);
  }
}

export async function getRecipes(): Promise<Recipe[]> {
  const response = await fetch(`${config.API_URL}/recipes`);

  if (!response.ok) {
    throw new Error(`Error fetching recipes: ${response.statusText}`);
  }

  const recipesData: Recipe[] = await response.json() as Recipe[];
  return recipesData;
}
