import { Box, Heading, Image, useBreakpointValue } from "@chakra-ui/react";

interface NotFoundProps {
  message?: string;
}

function NotFound(props: NotFoundProps) {
  const imageWidth = useBreakpointValue({ base: "100%", md: "75%", lg: "50%" });

  const { message = "404 - Page Not Found" } = props;
  return (
    <Box textAlign="center" maxW="960px" mx="auto">
      <Heading as="h1" size="2xl" mb={4}>
        { message }
      </Heading>
      <Image src="https://placekitten.com/500/500" loading="lazy" alt="404 Cat" maxW="500px" mx="auto" width={imageWidth}/>
    </Box>
  );
}

export default NotFound;
