import React, { useContext } from "react";
import { Recipe } from "../api/recipes/recipes";

// export interface User {
//   username: string;
//   email: string;
//   // ...add other user properties here
// }

interface Client {
  endpoint: string;
  fetchOptions: {};
}

interface Storage {
  [key: string]: string;
}

interface JWTToken {
  jwtToken: string;
}

interface SignInUserSession {
  idToken: {
    payload: {
      email: string;
    };
  };
  accessToken: JWTToken;
  refreshToken: JWTToken;
  clockDrift: number;
}

export interface User {
  username: string;
  pool: {
    userPoolId: string;
    clientId: string;
    client: Client;
    advancedSecurityDataCollectionFlag: boolean;
    storage: Storage;
  };
  Session: null;
  client: Client;
  signInUserSession: SignInUserSession;
}


interface AuthContextProps {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  recipes: Recipe[];
  setRecipes: React.Dispatch<React.SetStateAction<Recipe[]>>;
}

const AuthContext = React.createContext<AuthContextProps>({
  user: null,
  setUser: () => {},
  recipes: [],
  setRecipes: () => {},
});

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<User | null>(null);
  const [recipes, setRecipes] = React.useState<Recipe[]>([]);

  return (
    <AuthContext.Provider value={{ user, setUser, recipes, setRecipes }}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

function useUser(): User | null {
  const { user } = useAuth();
  return user;
}

export { AuthProvider, useAuth, useUser, AuthContext }; // Export AuthContext
