import {
  Box,
  Heading,
  SimpleGrid,
  Select,
  FormControl,
  FormLabel,
  Button,
  useBreakpointValue,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getRecipes } from "../api/recipes/recipes";
import useCurrentUser from "../api/auth/useCurrentUser";
import { useRecipes } from "../store/RecipeContext";
import RecipeCard from "../components/recipeCard/RecipeCard";
import { SpinnerIcon } from "@chakra-ui/icons";
import { SearchBar } from "../components/searchBar/searchBar";
import { getSuggestions } from "../api/ai/ai";

function Explore() {
  const { recipes, setRecipes } = useRecipes();
  const [isLoading, setIsLoading] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState("All");
  const columns = useBreakpointValue({ base: 2, lg: 4, });

  // The Set filters the array to be distinct values
  const categories = [
    "All",
    ...Array.from(new Set(recipes.map((recipe) => recipe.category))),
  ];

  const user = useCurrentUser();

  useEffect(() => {
    const fetchRecipes = async () => {
      const allRecipes = await getRecipes();
      setRecipes(allRecipes);
    };
    fetchRecipes();
  }, []);

  // This code filters an array of recipes based on the categoryFilter value
  // If the categoryFilter value is "All", the original array is returned.
  // Otherwise, the array is filtered to only include recipes that match the categoryFilter value
  const filteredRecipes =
    categoryFilter === "All"
      ? recipes
      : recipes.filter((recipe) => recipe.category === categoryFilter);

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setCategoryFilter(event.target.value);
  };

  const handleSearchQueryChange = async (query: string) => {
    setIsLoading(true);
    const suggestions = await getSuggestions(query);
    setRecipes(suggestions);
    setIsLoading(false);
  };

  return (
    <Box maxW="960px" mx="auto" py={8} px={4}>
      <Heading as="h1" size="2xl" mb={8}>
        Explore
      </Heading>
      <Box maxW="400px" mx="auto" py={4}>
        <Link to="/recipes/add">
          <Button colorScheme="teal" size="lg" width="full">
            Add a Recipe
          </Button>
        </Link>
      </Box>
      <FormControl mb={8}>
        <FormLabel htmlFor="category">Category</FormLabel>
        <Select
          id="category"
          value={categoryFilter}
          onChange={handleCategoryChange}
        >
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </Select>
      </FormControl>
      <SearchBar setSearchQuery={handleSearchQueryChange} renderSearchPage={false}/>
      {isLoading && (
        <Flex justifyContent="center" alignItems="center" height="100vh">
          <Icon as={SpinnerIcon} />
        </Flex>
      )}
      <SimpleGrid columns={columns} gap={4}>
        {filteredRecipes.map((recipe) => (
          <RecipeCard
            id={recipe.id || ""}
            author={recipe.author || ""}
            title={recipe.title}
            imageUrl={recipe.imageUrl || ""}
            description={recipe.description || ""}
            rating={recipe.rating || 4}
            category={recipe.category}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
}

export default Explore;
