import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import Header from "./components/header/Header";
import Home from "./views/Home";
import RecipePage from "./views/RecipePage";
import { useNavigate } from "react-router-dom";

import { extendTheme, type ThemeConfig } from "@chakra-ui/react";
import Settings from "./views/Settings";
import CategoryPage from "./views/CategoryPage";
import NotFound from "./views/NotFound";
import Profile from "./views/Profile";
import EditRecipe from "./views/AddRecipe";
import AddRecipe from "./views/AddRecipe";
import Explore from "./views/Explore";
import SearchResults from "./views/SearchResults";
import { Amplify, Hub } from "aws-amplify";
import awsExports from "./aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { AuthProvider, useAuth, User, useUser } from "./store/AuthProvider";
import { useContext, useEffect } from "react";
import { RecipeProvider } from "./store/RecipeContext";
import ChatPage from "./views/ChatPage";
// import { AuthContext } from "./store/AuthContext";

// Configure Amplify in index file or root file
Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
  oauth: {
    redirectSignIn: `${window.location.origin}/auth/callback`,
    redirectSignOut: `${window.location.origin}/auth/signout`,
    responseType: "code",
    scope: ["email", "openid", "profile"],
  },
});

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  fonts: {
    body: "Roboto Slab",
    heading: "Roboto Slab",
  },
});

function App() {
  const { setUser, user } = useAuth();

  useEffect(() => {
    // Subscribe to authentication events using Hub
    Hub.listen("auth", (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {
        setUser(payload.data);
        window.location.reload();
      }
      if (payload.event === "signOut") {
        setUser(null);
        window.location.reload();
      }
    });
  }, [setUser]);

  return (
    <HashRouter>
      <RecipeProvider>
        <AuthProvider>
          <ChakraProvider theme={theme}>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/recipes/:id" element={<RecipePage />} />
              <Route path="/chat" element={<ChatPage />} />
              <Route path="/recipes/add" element={<Authenticator loginMechanisms={["username", "email"]}><AddRecipe /></Authenticator>} />
              <Route path="/explore" element={<Explore />} />
              <Route path="/settings" element={<Settings />} />
              <Route
                path="/profile"
                element={<Profile key={user ? user.username : "logged-out"} />}
              />
              <Route path="/search" element={<SearchResults />} />
              <Route path="/explore/:category" element={<CategoryPage />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ChakraProvider>
        </AuthProvider>
      </RecipeProvider>
    </HashRouter>
  );
}

export default App;
