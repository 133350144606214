import { Box, Heading, Wrap, WrapItem, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRecipes, Recipe } from "../api/recipes/recipes";
import RecipeCard from "../components/recipeCard/RecipeCard";
import { Link } from "react-router-dom";
import { useRecipes } from "../store/RecipeContext";

function CategoryPage() {
  const { category } = useParams<{ category: string }>();
  const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const { recipes, setRecipes } = useRecipes()
  const [filteredRecipes, setFilteredRecipes] = useState<Recipe[]>([]);

  useEffect(() => {
    const fetchRecipes = async () => {
      const allRecipes = await getRecipes();
      setRecipes(allRecipes);
    };
    fetchRecipes();
  }, []);

  useEffect(() => {
    const filterRecipesByCategory = () => {
      const filtered = recipes.filter(
        (recipe) =>
          recipe.category.toLowerCase() === category?.toLocaleLowerCase()
      );
      setFilteredRecipes(filtered);
    };
    filterRecipesByCategory();
  }, [category, recipes]);

  return (
    <Box maxW="960px" mx="auto" py={8} px={4}>
      <Heading as="h1" size="2xl" mb={8}>
        {capitalize(category as string)} Recipes
      </Heading>
      <Box mt={4} py={4} px={4}>
        <Link to={`/recipes/add?category=${category}`}>
          <Button colorScheme="teal" size="lg">
            Add a new recipe
          </Button>
        </Link>
      </Box>
      <Wrap spacing={4}>
        {filteredRecipes
          .filter((recipe) => recipe.id !== null)
          .map((recipe) => (
            <WrapItem key={recipe.id}>
              <RecipeCard
                id={recipe.id || ""}
                author={recipe.author || ""}
                title={recipe.title}
                imageUrl={recipe.imageUrl || ""}
                description={recipe.description || ""}
                rating={recipe.rating || 4}
                category={recipe.category as string}
              />
            </WrapItem>
          ))}
      </Wrap>
    </Box>
  );
}

export default CategoryPage;
