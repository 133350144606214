import {
  Card,
  Flex,
  Avatar,
  Heading,
  CardBody,
  Box,
  Text,
} from "@chakra-ui/react";
import { Comment } from "../../api/comments/comments";

interface CommentProps {
  comment: Comment;
}

export function CommentCard(props: CommentProps) {
  const { comment } = props;
  const date = new Date(comment.timestamp);
  const formattedDate = date.toLocaleDateString();
  const formattedTime = date.toLocaleTimeString();
  return (
    <Card>
      <Box pt={5} pl={5}>
        <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
          <Avatar name={comment.author} />
          <Box>
            <Heading size="sm">{comment.author}</Heading>
            <Text>{formattedDate} {formattedTime}</Text>
          </Box>
        </Flex>
      </Box>
      <CardBody>
        <Text>{comment.text}</Text>
      </CardBody>
      {/* <CardFooter
        justify="space-between"
        flexWrap="wrap"
        sx={{
          "& > button": {
            minW: "136px",
          },
        }}
      >
        <Button flex="1" variant="ghost">
          Like
        </Button>
        <Button flex="1" variant="ghost">
          Comment
        </Button>
        <Button flex="1" variant="ghost">
          Share
        </Button>
      </CardFooter> */}
    </Card>
  );
}
